import { useState, useEffect, useMemo } from 'react';
import { Plus, Search } from 'react-feather';
import { AppButton } from '../../../general/AppButton/AppButton';
import { AppInputField } from '../../../general/AppInputField/AppInputField';
import { AppErrorPage } from '../../../general/AppErrorPage/AppErrorPage';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { COLORS } from '../../../../utils/colors';
import { getSupplierJobs } from '../../../../redux/services/supplierJobsService';
import { SupplierJobApiResponse } from '@dill/dill-shared/dist/src';
import SupplierJobsTable from '../SupplierJobsTable/SupplierJobsTable';
import { AppNewSupplierJobModal } from '../../../general/AppNewSupplierJobModal/AppNewSupplierJobModal';
import { getSupplierBuyers } from '../../../../redux/services/supplierBuyersService';
import { getSupplierLienTemplates } from '../../../../redux/services/leinTemplatesService';
import { getUserSignatures } from '../../../../redux/services/authService';
import useInfiniteScroll from 'react-infinite-scroll-hook';

const SupplierJobs = () => {
  const dispatch = useAppDispatch();
  const [addNewJob, setAddNewJob] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [toogle, setToogle] = useState(true);

  const { loadingErrorList } = useAppSelector((state) => state.supplierJobs);
  const [sortOptions, setSortOptions] = useState<{ sortOrder: 'asc' | 'desc'; sortBy: string }>({
    sortOrder: 'desc',
    sortBy: 'createdAt',
  });
  const [previousLastDocumentId, setPreviousLastDocumentId] = useState('');
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentSupplierJobs, setCurrentSupplierJobs] = useState<SupplierJobApiResponse[]>([]);

  const searchedSupplierJobs: SupplierJobApiResponse[] = useMemo(() => {
    let filteredSupplierJobs: SupplierJobApiResponse[] = [];
    const activeJobs = currentSupplierJobs.filter((job) => {
      if (toogle && job?.archivedState && job?.archivedState.includes('SUPPLIER_ARCHIVED')) {
        return false;
      }
      return true;
    });
    if (activeJobs && searchText) {
      const searched = activeJobs.filter((supplierJob) => {
        if (
          (supplierJob.name &&
            supplierJob.name.toString().toLowerCase().includes(searchText.toLowerCase())) ||
          (supplierJob.jobNumber &&
            supplierJob.jobNumber.toString().toLowerCase().includes(searchText.toLowerCase())) ||
          (supplierJob.buyer?.name &&
            supplierJob.buyer?.name.toString().toLowerCase().includes(searchText.toLowerCase())) ||
          (supplierJob.buyer?.address &&
            supplierJob.buyer?.address
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())) ||
          (supplierJob.buyer?.city &&
            supplierJob.buyer?.city.toString().toLowerCase().includes(searchText.toLowerCase())) ||
          (supplierJob.buyer?.state &&
            supplierJob.buyer?.state.toString().toLowerCase().includes(searchText.toLowerCase())) ||
          (supplierJob.buyer?.zipCode &&
            supplierJob.buyer?.zipCode
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())) ||
          (supplierJob.owner &&
            supplierJob.owner.toString().toLowerCase().includes(searchText.toLowerCase())) ||
          (supplierJob.ownerAddress &&
            supplierJob.ownerAddress.toString().toLowerCase().includes(searchText.toLowerCase())) ||
          (supplierJob.ownerCity &&
            supplierJob.ownerCity.toString().toLowerCase().includes(searchText.toLowerCase())) ||
          (supplierJob.ownerState &&
            supplierJob.ownerState.toString().toLowerCase().includes(searchText.toLowerCase())) ||
          (supplierJob.ownerZipCode &&
            supplierJob.ownerZipCode.toString().toLowerCase().includes(searchText.toLowerCase())) ||
          (supplierJob.jobAddress &&
            supplierJob.jobAddress.toString().toLowerCase().includes(searchText.toLowerCase())) ||
          (supplierJob.jobCity &&
            supplierJob.jobCity.toString().toLowerCase().includes(searchText.toLowerCase())) ||
          (supplierJob.jobState &&
            supplierJob.jobState.toString().toLowerCase().includes(searchText.toLowerCase())) ||
          (supplierJob.jobZipCode &&
            supplierJob.jobCity.toString().toLowerCase().includes(searchText.toLowerCase())) ||
          (supplierJob?.lender &&
            supplierJob.lender.toString().toLowerCase().includes(searchText.toLowerCase())) ||
          (supplierJob?.lenderAddress &&
            supplierJob.lenderAddress
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())) ||
          (supplierJob?.lenderCity &&
            supplierJob.lenderCity.toString().toLowerCase().includes(searchText.toLowerCase())) ||
          (supplierJob?.lenderState &&
            supplierJob.lenderState.toString().toLowerCase().includes(searchText.toLowerCase())) ||
          (supplierJob?.lenderZipCode &&
            supplierJob.lenderZipCode.toString().toLowerCase().includes(searchText.toLowerCase()))
        ) {
          return true;
        }
        return false;
      });
      filteredSupplierJobs = searched;
    } else {
      filteredSupplierJobs = activeJobs;
    }

    return filteredSupplierJobs;
  }, [currentSupplierJobs, searchText, toogle]);

  const error = useMemo(() => {
    if (loadingErrorList.length > 0) {
      const errorMessage: any = loadingErrorList.filter((obj) =>
        obj.hasOwnProperty('getSupplierJobs')
      );
      if (errorMessage.length > 0) {
        return errorMessage[0]?.getSupplierJobs;
      }
    }
    return null;
  }, [loadingErrorList]);

  const handleLoadMore = async (loadOptions?: { isFreshFetch?: boolean }) => {
    setLoading(true);
    const pageSize = 50;
    let currentPreviousLastDocumentId = previousLastDocumentId;
    let newSupplierJobs = currentSupplierJobs;
    if (loadOptions && loadOptions?.isFreshFetch) {
      currentPreviousLastDocumentId = '';
      newSupplierJobs = [];
    }
    const results = await dispatch(
      getSupplierJobs({
        includeInvoices: true,
        includePreLiens: true,
        previousLastDocumentId: currentPreviousLastDocumentId,
        pageSize,
        sortBy: sortOptions.sortBy,
        sortOrder: sortOptions.sortOrder,
      })
    );
    setLoading(false);
    if (results.type === 'supplierJobs/getSupplierJobs/fulfilled') {
      if (results.payload) {
        const payload = results.payload as any;
        const fetchedSupplierJobs: SupplierJobApiResponse[] = payload.data as any;
        const lastDockId = payload.lastDocumentId;

        if (!lastDockId) {
          setHasNextPage(false);
        } else if (lastDockId !== currentPreviousLastDocumentId) {
          setCurrentSupplierJobs([...newSupplierJobs, ...fetchedSupplierJobs]);
          setPreviousLastDocumentId(lastDockId);
          if (fetchedSupplierJobs.length === pageSize) {
            setHasNextPage(true);
          } else {
            setHasNextPage(false);
          }
        } else {
          setHasNextPage(false);
        }
      }
    }
  };

  useEffect(() => {
    handleLoadMore({ isFreshFetch: true });
    dispatch(getSupplierBuyers());
    dispatch(getSupplierLienTemplates());
    dispatch(getUserSignatures());
  }, []);

  const [infiniteRef] = useInfiniteScroll({
    loading: loading,
    hasNextPage: hasNextPage,
    onLoadMore: handleLoadMore,
    rootMargin: '0px 0px 400px 0px',
  });

  return (
    <div className="h-full p-8 flex ">
      <AppNewSupplierJobModal
        open={addNewJob}
        handleClose={() => {
          handleLoadMore({ isFreshFetch: true });
          setAddNewJob(false);
        }}
      />
      <div className="flex flex-col px-5 py-4 w-full bg-white">
        <div className="flex justify-between">
          <div className="flex items-center">
            {/* <AppInputField 
              inputFieldStyles={{ width: '300px' }}
              id="search"
              value={searchText}
              placeholder={'Search '}
              icon={<Search size={15} />}
              onTextChange={(text) => {
                setSearchText(text);
              }}
            /> */}
            <div className="flex ml-2">
              <div
                onClick={() => setToogle(!toogle)}
                className={`w-[40px] h-[22px] rounded-full p-[1px] grid flex-col cursor-pointer ${
                  toogle ? 'justify-items-end bg-[#3862fb]' : 'GREY_500-BG'
                }`}>
                <div className="bg-white w-[20px] h-[20px] rounded-full" />
              </div>
              <div className="w-[180px] ml-2 font-semibold">Show Only Active Jobs</div>
            </div>
          </div>
          <AppButton
            text="Add New Job"
            icon={<Plus color={COLORS.WHITE} />}
            onClick={() => setAddNewJob(true)}
          />
        </div>
        {error ? (
          <AppErrorPage
            title="Error loading  jobs."
            contactMessage="Please contact customer support."
            errorMessage={error}
          />
        ) : (
          <SupplierJobsTable
            supplierJobs={searchedSupplierJobs}
            setSupplierJobs={setCurrentSupplierJobs}
            infiniteRef={infiniteRef}
            hasNextPage={hasNextPage}
            loading={loading}
            sortOptions={sortOptions}
            onReload={() => {
              handleLoadMore({ isFreshFetch: true });
            }}
            onSortChange={({ sortBy, sortOrder }) => {
              setSortOptions({ sortBy, sortOrder });
            }}
          />
        )}
      </div>
    </div>
  );
};

export default SupplierJobs;
